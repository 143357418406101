import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import '../App.css';
import EmailNotVerified from "../pages/EmailNotVerifiedPage";
import FaqPage from "../pages/faq/page";
import InspectionDashboardPage from '../pages/inspection/page';
import InternalPage from '../pages/internal/page';
import LoginPage from "../pages/LoginPage";
import MetrologyPage from '../pages/metrology/page';
import OperatorPage from '../pages/operator/page';
import ScansPage from '../pages/scans/page';
import SlicesPage from '../pages/slices/page';
import SomethingWentWrongPage from '../pages/SomethingWentWrongPage';
import SubscriptionLoadingPage from "../pages/SubscriptionLoadingPage";
import TosAcceptor from '../pages/terms_of_service/TosAcceptor';
import TosViewer from "../pages/terms_of_service/TosViewer";
import { PageOptions, ScanList, UserAccessState } from "../types";
import { appTopNavBarHeight } from '../utils';
import LeftNavigationDrawer from './LeftNavigationDrawer';
import TopNavigationBar from './TopNavigationBar';

interface AppMainLayoutComponentProps {
    selectedPage: PageOptions,
    userAccessState: UserAccessState,
    isLoginLoading: boolean,
    isUserAuthenticated: boolean,
    scanList: ScanList[],
    isDemoMode: boolean,
    handleDemoToggle: () => void,
}

export default function AppMainLayoutComponent(
    {
        selectedPage,
        userAccessState,
        isLoginLoading,
        isUserAuthenticated,
        scanList,
        isDemoMode,
        handleDemoToggle,
    }: AppMainLayoutComponentProps
) {
    const drawerWidth = 52;
    const leftAndRightMargin = 25;

    const noScansNote = <Grid
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            pt: "14pt",
        }}>
        No scans were found on your subscription.
    </Grid>;

    const pageHandler = (
        isLoading: boolean,
        isAuthenticated: boolean,
        page: PageOptions,
        userAccessState: UserAccessState
    ) => {
        if (isLoading) return <SubscriptionLoadingPage />;

        // Early returns for specific pages
        if (page === PageOptions.FAQ) return <FaqPage userAccessState={userAccessState} />;
        if (page === PageOptions.TERMS) return <TosViewer />;

        if (!isAuthenticated) { return <LoginPage />; }
        else { clearUrlParams(); }

        // Render based on user access state
        switch (userAccessState) {
            case UserAccessState.LOADING:
                return <SubscriptionLoadingPage />;
            case UserAccessState.EMAIL_UNVERIFIED:
                return <EmailNotVerified />;
            case UserAccessState.SHOW_TERMS_PAGE:
                return <TosAcceptor />;
            case UserAccessState.SIGNUP_COMPLETE:
            case UserAccessState.SIGNUP_INCOMPLETE:
            case UserAccessState.GLIMPSE_INTERNAL_READ:
            case UserAccessState.GLIMPSE_INTERNAL_WRITE:
            case UserAccessState.CUSTOMER_OPERATOR:
                return renderUserPages(page, userAccessState);
            default:
                return <SomethingWentWrongPage />;
        }
    };

    const clearUrlParams = () => {
        const params = new URLSearchParams(window.location.search);
        params.delete("code");
        params.delete("state");
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    };

    // Helper function to render user-specific pages
    const renderUserPages = (page: PageOptions, userAccessState: UserAccessState) => {
        if (scanList.length === 0) return noScansNote;
        switch (page) {
            case PageOptions.OPERATOR:
                return <OperatorPage userAccessState={userAccessState} />;
            case PageOptions.SCANS:
                return <ScansPage scanList={scanList} isDemoMode={isDemoMode} />;
            case PageOptions.SLICES:
                return <SlicesPage scanList={scanList} isDemoMode={isDemoMode} />;
            case PageOptions.metrology:
                return <MetrologyPage scanList={scanList} isDemoMode={isDemoMode} />;
            case PageOptions.INSPECTION_DASHBOARD:
                return <InspectionDashboardPage scanList={scanList} isDemoMode={isDemoMode} />;
            case PageOptions.INTERNAL_READ:
            case PageOptions.INTERNAL_WRITE:
                if ([UserAccessState.GLIMPSE_INTERNAL_READ, UserAccessState.GLIMPSE_INTERNAL_WRITE].includes(userAccessState)) {
                    return <InternalPage selectedPage={page} userAccessState={userAccessState} />;
                }
                break;
        }
        return <SomethingWentWrongPage />;
    };


    return (
        < >
            <TopNavigationBar
                isDemoMode={isDemoMode}
                handleDemoToggle={handleDemoToggle}
                userAccessState={userAccessState}
                selectedPage={selectedPage}
                topBarHeight={appTopNavBarHeight}
            />
            <LeftNavigationDrawer
                drawerWidth={drawerWidth}
                selectedPage={selectedPage}
                userAccessState={userAccessState}
                isDemoMode={isDemoMode}
            />
            <Box sx={{
                height: '100%',
                mt: `${appTopNavBarHeight}px`,
                ml: `${drawerWidth + leftAndRightMargin}px`,
                mr: `${leftAndRightMargin}px`,
            }}>
                {pageHandler(isLoginLoading, isUserAuthenticated, selectedPage, userAccessState)}
            </Box>
        </ >
    )
}
