import { Checkbox, ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { InspectionChartData } from '../../utils/inspection/dashboard';


export default function MultiBatchSelect(
    {
        requestsWithData,
        selectedRequests,
        filteredData,
        setSelectedRequests
    }: {
        requestsWithData: {
            request_name: string;
            count: number;
        }[],
        selectedRequests: string[]
        filteredData: InspectionChartData[];
        setSelectedRequests: (selectedRequests: string[]) => void;
    }) {

    const handleSelectAllRequests = () => {
        if (requestsWithData.length !== selectedRequests.length) {
            setSelectedRequests(requestsWithData.map((e) => e.request_name));
        } else {
            setSelectedRequests([]);
        }
    };

    const handleCheckboxChange = (option: string) => {
        const currentIndex = selectedRequests.indexOf(option);
        const newSelectedOptions = [...selectedRequests];
        if (currentIndex === -1) {
            newSelectedOptions.push(option);
        } else {
            newSelectedOptions.splice(currentIndex, 1);
        }
        setSelectedRequests(newSelectedOptions);
    };


    const renderValueRequest = () => {
        if (selectedRequests.length === 0) {
            return 'None';
        } else if (selectedRequests.length === requestsWithData.length) {
            return `All (${selectedRequests.length}) batches.` //${rawInspectionChartData.length} scans)`;
        } else if (selectedRequests.length === 1) {
            return `${selectedRequests[0]} (${requestsWithData.find((e) => e.request_name === selectedRequests[0])?.count} scans)`;
        } else {
            return `${selectedRequests.length} of ${requestsWithData.length} batches (${filteredData.length} scans)`;
        }
    };

    return <FormControl >
        <InputLabel >Viewing Batches:</InputLabel>
        <Select
            label="Viewing Batches:"
            multiple
            value={selectedRequests}
            onChange={() => { }}
            renderValue={renderValueRequest}
        >
            <MenuItem dense >
                <Checkbox
                    sx={{ p: 0.5, m: 0 }}
                    checked={selectedRequests.length === requestsWithData.length}
                    onChange={handleSelectAllRequests} />
                <ListItemText primary={"All"} />
            </MenuItem>
            {requestsWithData.map((option) => (
                <MenuItem dense key={option.request_name} value={option.request_name}>
                    <Checkbox
                        sx={{ p: 0.5, m: 0 }}
                        checked={selectedRequests.indexOf(option.request_name) > -1}
                        onChange={() => handleCheckboxChange(option.request_name)} />
                    <ListItemText primary={
                        option.request_name
                    } />
                </MenuItem>
            ))}
        </Select>
    </FormControl>

}
