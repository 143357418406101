import { FormControlLabel, Grid } from '@mui/material';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import { colorScheme } from '../../utils/colorScheme';


export interface SeriesCheckboxesState {
    max: boolean;
    min: boolean;
    mean: boolean;
    median: boolean;
}

export default function SeriesCheckboxes(
    {
        checkboxes,
        setCheckboxes
    }: {
        checkboxes: SeriesCheckboxesState,
        setCheckboxes: React.Dispatch<React.SetStateAction<SeriesCheckboxesState>>
    }) {


    const toggleCheckbox = (key: keyof SeriesCheckboxesState) => {
        setCheckboxes((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    const checkboxOptions = [
        { key: "max", label: "Max", color: colorScheme.max },
        { key: "min", label: "Min", color: colorScheme.min },
        { key: "mean", label: "Mean", color: colorScheme.average },
        { key: "median", label: "Median", color: colorScheme.average },
    ];

    return (
        <Grid container direction="row" sx={{ pl: 1 }}>
            {checkboxOptions.map(({ key, label, color }) => (
                <Grid xs={3} item key={key}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size='small'
                                sx={{ [`&, &.${checkboxClasses.checked}`]: { color }, p: 0.5 }}
                                checked={checkboxes[key as keyof typeof checkboxes]}
                                onChange={() => toggleCheckbox(key as keyof typeof checkboxes)}
                            />
                        }
                        label={label}
                    />
                </Grid>
            ))}
        </Grid>
    );

}
