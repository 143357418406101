import { Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MetricChartPageOptions } from '../../utils/inspection';


export default function MultiMetricSelect(
    {
        selectedMetrics,
        availableMetricOptions,
        setSelectedMetrics,
    }: {
        selectedMetrics: MetricChartPageOptions[]
        availableMetricOptions: MetricChartPageOptions[]
        setSelectedMetrics: (selectedMetrics: MetricChartPageOptions[]) => void
    }) {


    const inputLabel = selectedMetrics.length > 0 ? 'Viewing Metrics:' : 'Select Metrics';

    const handleChange = (event: SelectChangeEvent<typeof selectedMetrics>) => {
        const { target: { value } } = event;
        setSelectedMetrics(
            // On autofill we get a stringified value.
            typeof value === 'string' ?
                value.split(',') as MetricChartPageOptions[] :
                value as MetricChartPageOptions[]
        );
    };

    return <FormControl sx={{ mt: 1 }}>
        <InputLabel >{inputLabel}</InputLabel>
        <Select
            multiple
            value={selectedMetrics}
            onChange={handleChange}
            input={<OutlinedInput label={inputLabel} />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (<Chip key={value} label={value} variant="outlined" />))}
                </Box>
            )}
            MenuProps={{ PaperProps: { sx: { maxHeight: 400, overflowY: 'auto' } } }}
        >
            {availableMetricOptions.map((e) => (
                <MenuItem dense key={e} value={e}>
                    <Checkbox sx={{ p: 0.5, m: 0 }} checked={selectedMetrics.indexOf(e) > -1} /> {e}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
}
