import { Box, Card, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import { useEffect, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../../auth/authHooks';
import FacetedPlot, { ChartSeriesOptions, PlotData } from '../../components/InspectionDashboard/FacetPlot';
import MultiBatchSelect from '../../components/InspectionDashboard/MultiBatchSelect';
import MultiMetricSelect from '../../components/InspectionDashboard/MultiMetricSelect';
import ScanFilterAutocomplete from '../../components/InspectionDashboard/ScanFilterAutocomplete';
import SeriesCheckboxes from '../../components/InspectionDashboard/SeriesCheckboxes';
import { ScanList } from '../../types';
import { ApiEndpoints } from '../../utils/apiUtils';
import { MetricChartPageOptions } from '../../utils/inspection';
import { countRequestGroups, InspectionChartData, xAxisTypes } from '../../utils/inspection/dashboard';

// enum SortByOptions {
//     SCAN_ID = 'scan_id',
//     // SCAN_DATE = 'scan_date',
//     BATCH = 'batch',
//     MEAN_VALUE = 'mean_value',
// }

export default function InspectionDashboardPage(
    {
        scanList,
        isDemoMode,
    }: {
        scanList: ScanList[],
        isDemoMode: boolean,
    }) {

    const { fetchData } = useAuthTokenAndAccessApi();
    const [rawChartData, setRawChartData] = useState<InspectionChartData[]>([]);
    const [isLoading, setLoadingStatus] = useState(true)
    const [selectedMetrics, setSelectedMetrics] = useState<MetricChartPageOptions[]>([])
    const [selectedRequests, setSelectedRequests] = useState<string[]>([]);
    // const [sortBy, setSortBy] = useState<SortByOptions>(SortByOptions.SCAN_ID)
    const [filterScanIds, setFilterScanIds] = useState<number[] | null>(null)
    const [checkboxes, setCheckboxes] = useState({
        max: true,
        min: true,
        mean: true,
        median: true,
    });

    // const [granularity, setGranularity] = useState(Granularity.SCAN)
    // const [resetChartZoom, setResetChartZoom] = useState(false);
    const [xAxisType, setXAxisType] = useState(xAxisTypes.SCAN_ID);

    /*
    naming and units cleanup
    sorting by value
    filter by scans

    filter by value  [{ field: 'rating', operator: '>', value: '2.5' }],
    add tolerance lines per metric.
    plot by date
    filter by date if applicable
    plot by other metric /faceting into a grid of plots for up to three metrics
    */


    useEffect(() => {
        setLoadingStatus(true)
        async function initialDataLoader(isDemoMode: boolean) {
            getChartData(isDemoMode)
        }
        if (scanList.length > 0) initialDataLoader(isDemoMode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanList, isDemoMode]);

    async function getChartData(isDemoMode: boolean) {
        setRawChartData([]);
        try {
            const params: any = isDemoMode ? { is_demo: true } : {};
            const response = await fetchData(ApiEndpoints.INSPECTION_CHART_RESULTS, params);
            const data = response.data
            const joinedData = data
                .map((e: {
                    metric_internal_name: MetricChartPageOptions,
                    scan_id: number,
                    min: number,
                    max: number,
                    mean: number,
                    median: number,
                    min_slice_id: number,
                    max_slice_id: number,
                }
                ) => {
                    const foundData = scanList.find((s) => s.scan_id === e.scan_id);
                    if (!foundData || !foundData.request_id || !foundData.request_name || !foundData.post_processing_completed) {
                        return null;
                    }
                    return {
                        ...e,
                        sn: foundData.cell_sn,
                        request_id: foundData.request_id,
                        request_name: foundData.request_name,
                        scan_date: foundData.post_processing_completed
                    };
                })
                .filter(Boolean) as InspectionChartData[];
            setRawChartData(joinedData)
            const uniqueRequests = Array.from(new Set(joinedData.map((e) => e.request_name)))
            const uniqueMetrics = Array.from(new Set(joinedData.map((e) => e.metric_internal_name)))
            setSelectedMetrics(uniqueMetrics.slice(0, 3))
            setSelectedRequests(uniqueRequests)
            setLoadingStatus(false)
        } catch (error: any) { console.error(error) }
    }
    const requestsWithData = countRequestGroups(rawChartData)

    const availableMetricOptions = Array.from(new Set(rawChartData.map((e) => e.metric_internal_name)))

    const transformData = (data: InspectionChartData[]): PlotData[] => {
        return data.flatMap(({
            scan_id, sn, metric_internal_name, request_name, scan_date,
            mean, median, min, min_slice_id, max, max_slice_id
        }) => [
                {
                    scanId: scan_id, metric: metric_internal_name, sn: sn,
                    series: ChartSeriesOptions.MEAN, sliceId: null, value: mean, batch: request_name, date: scan_date
                },
                {
                    scanId: scan_id, metric: metric_internal_name, sn: sn,
                    series: ChartSeriesOptions.MEDIAN, sliceId: null, value: median, batch: request_name, date: scan_date
                },
                {
                    scanId: scan_id, metric: metric_internal_name, sn: sn,
                    series: ChartSeriesOptions.MIN, sliceId: min_slice_id, value: min, batch: request_name, date: scan_date
                },
                {
                    scanId: scan_id, metric: metric_internal_name, sn: sn,
                    series: ChartSeriesOptions.MAX, sliceId: max_slice_id, value: max, batch: request_name, date: scan_date
                },
            ]);
    };

    const filteredData = rawChartData
        .filter((e) => selectedMetrics.includes(e.metric_internal_name))
        .filter((e) => selectedRequests.includes(e.request_name))

    const filteredScans = filterScanIds === null ? filteredData : filteredData.filter((e) => filterScanIds.includes(e.scan_id))

    const tidyPlotData = transformData(filteredScans)
        .filter((e) => e.series !== ChartSeriesOptions.MEDIAN || checkboxes.median)
        .filter((e) => e.series !== ChartSeriesOptions.MEAN || checkboxes.mean)
        .filter((e) => e.series !== ChartSeriesOptions.MIN || checkboxes.min)
        .filter((e) => e.series !== ChartSeriesOptions.MAX || checkboxes.max)


    return (
        <Box >
            <Typography variant="h5" sx={{ flexGrow: 1, pt: 2, pb: 1, pl: 1 }}>
                Automated Inspection Review
            </Typography>
            {
                isLoading &&
                <Typography variant="body1" sx={{ flexGrow: 1, pt: 2, pb: 1, pl: 1 }}>
                    Loading...
                </Typography>
            }
            {
                !isLoading &&
                <Stack direction="row" spacing={2}>
                    <Card elevation={0} sx={{ minWidth: 300, width: 300 }} >
                        <Stack direction="column" spacing={2}>
                            <div></div>
                            <MultiBatchSelect
                                requestsWithData={requestsWithData}
                                selectedRequests={selectedRequests}
                                filteredData={filteredData}
                                setSelectedRequests={setSelectedRequests}
                            />
                            <MultiMetricSelect
                                selectedMetrics={selectedMetrics}
                                availableMetricOptions={availableMetricOptions}
                                setSelectedMetrics={setSelectedMetrics}
                            />
                            <ScanFilterAutocomplete
                                filterScanIds={filterScanIds}
                                scanListWithMetrics={filteredData}
                                setFilterScanIds={setFilterScanIds}
                            />
                            <SeriesCheckboxes checkboxes={checkboxes} setCheckboxes={setCheckboxes} />
                            <Typography variant="body1" sx={{ p: 0, m: 0 }}>
                                X-Axis by:
                            </Typography>
                            <ToggleButtonGroup
                                value={xAxisType}
                                size='small'
                                exclusive
                                onChange={(_, newValue) => setXAxisType(newValue)}
                                aria-label="text alignment"
                            >
                                <ToggleButton value={xAxisTypes.SCAN_ID} aria-label="left aligned">
                                    Scan ID
                                </ToggleButton>
                                <ToggleButton disabled value={xAxisTypes.DATE} aria-label="centered">
                                    Date
                                </ToggleButton>
                                <ToggleButton value={xAxisTypes.CROSS_COMPARE} aria-label="right aligned">
                                    Cross Compare
                                </ToggleButton>
                            </ToggleButtonGroup>

                            {/* <DownloadChartData
                            chartData={lineChartData}
                            metric_name={selectedMetric}
                            granularity={granularity}
                            metricDisplayUnit={metricDisplayUnit(selectedMetric)}
                            filteredData={filteredData}
                         />
                        */}
                        </Stack>
                    </Card>

                    < Stack direction="column"  >
                        <Card elevation={0} sx={{ minWidth: 600, minHeight: 600, width: "70vw", borderRadius: 0, p: 3 }} variant='outlined'>
                            <FacetedPlot data={tidyPlotData} xAxisType={xAxisType} />
                            {
                                tidyPlotData.length === 0 && !isLoading &&
                                <Typography variant="body1" sx={{ flexGrow: 1, pt: 2, pb: 1, pl: 1 }}>
                                    No data, check filters.
                                </Typography>}
                        </Card>
                    </Stack>
                </Stack >
            }

        </Box >
    );
}
