import { AutomatedInspectionMetrics, MetricChartPageOptions } from "."
import { ScanList } from "../../types"

export interface MetrologyDataRequest {
    metric_id: number,
    request_id: number,
    request_name: string,
    scan_id: number,
    max_value: number,
    min_value: number,
    avg_value: number,
}

export interface InspectionChartData {
    metric_internal_name: MetricChartPageOptions,
    request_id: number,
    request_name: string,
    scan_date: Date,
    scan_id: number,
    sn: string,
    min: number,
    max: number,
    mean: number,
    median: number,
    min_slice_id: number,
    max_slice_id: number,
}


export enum Granularity {
    SCAN = 'scan_id',
    REQUEST = 'request_id',
}

export enum xAxisTypes {
    SCAN_ID = 'scan_id',
    DATE = 'date',
    CROSS_COMPARE = 'cross_compare',
}


export const makeLabel = (scanList: ScanList[], granularity: keyof MetrologyDataRequest, id: number) => {
    switch (granularity) {
        case Granularity.SCAN:
            return scanList.find((e) => e.scan_id === id)?.cell_sn.toString() || ""
        case Granularity.REQUEST:
            return scanList.find((e) => e.request_id === id)?.request_name.toString() || ""
        default:
            return granularity.toString() + ': ' + id.toString()
    }
}

export const makeTooltip = (scanList: ScanList[], granularity: keyof MetrologyDataRequest, id: number) => {
    switch (granularity) {
        case Granularity.SCAN:
            const item = scanList.find((e) => e.scan_id === id)
            return item?.cell_model_vendor.concat(" ", item.cell_model_name) || ""
        case Granularity.REQUEST:
            return scanList.find((e) => e.request_id === id)?.cell_model_vendor.toString() || ""
        default:
            return granularity.toString() + ': ' + id.toString()
    }
}


export const calculateRequestAggregates = (data: MetrologyDataRequest[]) => {
    const groupedData: { [key: number]: number[] } = {};
    const result = [];
    for (const item of data) {
        const key = item.request_id;
        if (!groupedData[key]) { groupedData[key] = []; }
        groupedData[key].push(item.avg_value);
    }
    for (const key in groupedData) {
        const values = groupedData[key];
        const id = parseInt(key);
        const sum = values.reduce((acc, val) => acc + val, 0);
        const count = values.length;
        const average = sum / count;
        const min = Math.min(...values);
        const max = Math.max(...values);
        result.push({ id, average, min, max });
    }
    return result;
}

export const metricMapping: Record<MetricChartPageOptions, AutomatedInspectionMetrics | string> = {
    [MetricChartPageOptions.CATHODE_WIDTH]: AutomatedInspectionMetrics.CATHODE_WIDTH,
    [MetricChartPageOptions.ANODE_OVERHANG_ALL]: AutomatedInspectionMetrics.ANODE_OVERHANG_ALL,
    [MetricChartPageOptions.ANODE_OVERHANG_TOP]: AutomatedInspectionMetrics.ANODE_OVERHANG_TOP,
    [MetricChartPageOptions.ANODE_OVERHANG_BOTTOM]: AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM,
    [MetricChartPageOptions.CORE_CIRCULARITY_DMIN_DMAX]: AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX,
    [MetricChartPageOptions.CORE_AREA]: AutomatedInspectionMetrics.CORE_AREA,
    [MetricChartPageOptions.CORE_CIRCULARITY_MCC]: AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC,
    [MetricChartPageOptions.CAN_INNER_DIAMETER_MEAN]: AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN,
    [MetricChartPageOptions.CAN_OUTER_DIAMETER_MEAN]: AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN,
    [MetricChartPageOptions.ANODE_TOP_BOTTOM_ASYSMMETRY]: AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY,
    [MetricChartPageOptions.CAN_WALL_THICKNESS_MEAN]: AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN,
    [MetricChartPageOptions.CAN_CIRCULARITY_DMIN_DMAX]: AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX,
    [MetricChartPageOptions.CAN_MAX_DENTING]: AutomatedInspectionMetrics.CAN_MAX_DENTING,
    [MetricChartPageOptions.CORE_EFFECTIVE_DIAMETER]: AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER,
    [MetricChartPageOptions.CORE_CONCENTRICITY]: AutomatedInspectionMetrics.CORE_CONCENTRICITY,
    [MetricChartPageOptions.ANODE_TAB_CURVATURE]: AutomatedInspectionMetrics.ANODE_TAB_CURVATURE,
    // we got ourselves into some trouble with the crimp metrics ("left and right" and "min and max" are
    // how they're stored in the db)
    [MetricChartPageOptions.CRIMP_HEIGHT]: "crimp_height",
    [MetricChartPageOptions.CRIMP_GROOVE_GAP]: "crimp_groove_gap",
};


export function countRequestGroups(items: InspectionChartData[]): { request_name: string; count: number }[] {
    const counts = items.reduce<Record<string, number>>((acc, item) => {
        acc[item.request_name] = (acc[item.request_name] || 0) + 1;
        return acc;
    }, {});
    return Object.entries(counts).map(([request_name, count]) => ({
        request_name,
        count
    }));
}
