import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Checkbox, List, ListItem, ListItemIcon, ListItemText, Menu, TextField } from "@mui/material";
import { useState } from "react";
import { formatIsoDate } from "../../utils";
import { InspectionChartData } from "../../utils/inspection/dashboard";

export default function ScanFilterAutocomplete(
    {
        filterScanIds,
        scanListWithMetrics,
        setFilterScanIds
    }: {
        filterScanIds: number[] | null,
        scanListWithMetrics: InspectionChartData[],
        setFilterScanIds: React.Dispatch<React.SetStateAction<number[] | null>>
    }) {

    const uniqueScans = scanListWithMetrics.reduce<{ scan_id: number; sn: string; scan_date: Date }[]>((acc, curr) => {
        if (!acc.some(item => item.scan_id === curr.scan_id)) {
            acc.push({ scan_id: curr.scan_id, sn: curr.sn, scan_date: curr.scan_date });
        }
        return acc;
    }, []);


    const [anchorEl, setAnchorEl] = useState(null);
    const [filter, setFilter] = useState("");

    const formatText = (e: {
        scan_id: number;
        sn: string;
        scan_date: Date;
    }) => `${e.scan_id}: ${e.sn}, ${formatIsoDate(e.scan_date)}`

    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = (scan_id: number) => {
        if (filterScanIds === null) {
            setFilterScanIds([scan_id]);
        } else {
            if (filterScanIds.includes(scan_id)) {
                setFilterScanIds(filterScanIds.filter((e) => e !== scan_id));
            } else {
                setFilterScanIds([...filterScanIds, scan_id]);
            }
        }
    };

    const handleSelectAll = () => {
        if (filterScanIds === null) {
            setFilterScanIds(uniqueScans.map((e) => e.scan_id));
        }
        else if (filterScanIds.length === uniqueScans.length) {
            setFilterScanIds([]);
        }
        else {
            setFilterScanIds(null);
        };
    }

    const numberSelected = filterScanIds === null ? uniqueScans.length : filterScanIds.length;

    return (
        <Box>
            <Button fullWidth variant="outlined" size="small" onClick={handleOpen}><FilterListIcon />Filter scans ({numberSelected} of {uniqueScans.length})</Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <Box sx={{ p: 1, width: 300, height: 400, }}>
                    <TextField
                        fullWidth
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                    <ListItem dense onClick={handleSelectAll}>
                        <ListItemIcon>
                            <Checkbox
                                sx={{ p: 0, m: 0 }}
                                checked={filterScanIds === null}
                                indeterminate={filterScanIds !== null && filterScanIds.length > 0 && filterScanIds.length < uniqueScans.length}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                    </ListItem>
                    <List dense>
                        {uniqueScans.filter((e) => e.sn.toLowerCase().concat(e.scan_id.toString()).includes(filter.toLowerCase())).
                            map((e) => (
                                <ListItem key={e.scan_id} onClick={() => handleToggle(e.scan_id)}>
                                    <ListItemIcon>
                                        <Checkbox sx={{ p: 0, m: 0 }} checked={filterScanIds === null || filterScanIds.includes(e.scan_id)} />
                                    </ListItemIcon>
                                    <ListItemText primary={formatText(e)} />
                                </ListItem>
                            ))}
                    </List>
                </Box>
            </Menu>
        </Box>
    );
};




// import { Box, Checkbox, FormControlLabel, MenuItem, TextField } from "@mui/material";
// import Autocomplete from "@mui/material/Autocomplete";
// import { useState } from "react";

// const options = ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"];

// export default function ScanFilterAutocomplete() {
//     const [selectedOptions, setSelectedOptions] = useState([] as string[]);

//     const handleChange = (event: any, newValue: any) => {
//         setSelectedOptions(newValue);
//     };

//     const handleSelectAll = (event: any) => {
//         if (event.target.checked) {
//             setSelectedOptions(options);
//         } else {
//             setSelectedOptions([]);
//         }
//     };

//     return (
//         <Box >
//             <Autocomplete
//                 multiple
//                 options={options}
//                 disableCloseOnSelect
//                 getOptionLabel={(option) => option}
//                 value={selectedOptions}
//                 onChange={handleChange}
//                 renderOption={(props, option, { selected }) => (
//                     <MenuItem {...props} key={option}>
//                         <Checkbox checked={selected} />
//                         {option}
//                     </MenuItem>
//                 )}
//                 renderInput={(params) => (
//                     <TextField
//                         {...params}
//                         label="Filter Scans"
//                         variant="outlined"
//                         InputProps={{
//                             ...params.InputProps,
//                             startAdornment: `${selectedOptions.length} of ${options.length} selected`,
//                         }}
//                     />
//                 )}
//             />
//             <FormControlLabel
//                 control={<Checkbox checked={selectedOptions.length === options.length} onChange={handleSelectAll} />}
//                 label="Select All"
//                 sx={{ marginTop: 1 }}
//             />
//         </Box>
//     );
// };
